import React from "react";
import cx from "classnames";
import logo from "./logo.svg";
import styles from "./Home.module.scss";

const Home = () => {
    return (
        <main className={styles["landing__offer"]}>
            <header className={styles["landing__navbar"]}>
                <div className={styles["landing__navbar-main"]}>
                    <a className={styles["landing__logo"]} href="/">
                        <img src={logo} alt="aixsurge"/>
                    </a>
                </div>
            </header>
            <picture className={styles["landing__picture"]}>
                <source media="(max-width: 768px)"
                        type="image/jpeg"
                        srcSet="/background-793771003-xs.jpg"
                />
                <source media="(min-width: 768px)"
                        type="image/jpeg"
                        srcSet="/background-793771003-lg.jpg"
                />
                <img className={styles["landing__img"]}
                     src="/background-793771003.jpg"
                     alt="img"/>
            </picture>
            <div className={styles["landing__text"]}>
                <div className={styles["landing__cta"]}>
                    <h1 className={styles["landing__title"]}>Elevate Your Triathlon Game</h1>
                    <p className={styles["landing__descr"]}>
                        Discover the ultimate triathlon training experience with aixsurge&nbsp;&mdash; where science
                        meets&nbsp;AI for enduring transformations.
                    </p>
                    <div className={styles["landing__plan-builder"]}>
                        <a href="/welcome"
                           className={cx(styles["landing__plan-builder-btn"], styles["landing__plan-builder-btn--shine"])}>
                            Continue ↵
                        </a>
                    </div>
                </div>
            </div>
            <a className="mx-4 lg:mx-16 my-16 text-sm text-gray-500 hover:text-blue-900" href="/wiki/privacy">Privacy Policy</a>
        </main>
    );
}

export default Home;
