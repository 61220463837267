import React from "react";

const Privacy = () => {
    return (
        <div className="mx-auto w-2/3 py-8">
            <h1 className="font-medium text-4xl mb-4">Privacy Policy</h1>
            <div className="space-y-4">
                <p>
                    Aixsurge, is&nbsp;responsible for the processing of&nbsp;personal data as&nbsp;shown in&nbsp;this
                    privacy statement.<br/>
                    Contact details: help@aixsurge.com
                </p>
                <p><strong>Personal data that we&nbsp;process</strong><br/>
                    Aixsurge processes your personal data because you use our services
                    and/or because you provide them to&nbsp;us yourself. Below you will find an&nbsp;overview
                    of&nbsp;the personal data we&nbsp;process:<br/>
                    First and last name<br/>
                    Sex<br/>
                    Date of&nbsp;birth<br/>
                    Address data<br/>
                    Phone number<br/> E-mail address<br/>
                    IP&nbsp;address<br/> Other personal data that you actively provide, for example by&nbsp;creating
                    a&nbsp;profile on&nbsp;this website, in&nbsp;correspondence and by&nbsp;telephone <br/>
                    Location data<br/>
                    Information about your activities on&nbsp;our website
                </p>
                <p><strong>Special and/or sensitive personal data that
                    we&nbsp;process</strong><br/>
                    Aixsurge processes the following special and/or sensitive personal data about
                    you:<br/>
                    health
                </p>
                <p><strong>For what purpose and on&nbsp;what basis do&nbsp;we&nbsp;process personal
                    data?</strong><br/>
                    Aixsurge processes your personal data for the following purposes:</p>
                <p>Handling your payment Sending our newsletter and/or advertising brochure To&nbsp;be&nbsp;able
                    to&nbsp;call or&nbsp;email you if&nbsp;this is&nbsp;necessary to&nbsp;provide our services
                    To&nbsp;inform you about changes to&nbsp;our services and products Offer you the opportunity
                    to&nbsp;create an&nbsp;account Aixsurge analyzes your behavior on&nbsp;the website in&nbsp;order
                    to&nbsp;improve the website and tailor the range of&nbsp;products and services to&nbsp;your
                    preferences. Aixsurge follows your surfing behavior on&nbsp;various websites with which
                    we&nbsp;tailor our products and services to&nbsp;your needs. &mdash;&nbsp;Aixsurge also
                    processes
                    personal data if&nbsp;we&nbsp;are legally obliged to&nbsp;do&nbsp;so, such as&nbsp;data that
                    we&nbsp;need for our tax return.
                </p>
                <p><strong>How long we&nbsp;keep personal data</strong><br/>
                    Aixsurge does not store your
                    personal data for longer than is&nbsp;strictly necessary to&nbsp;achieve the purposes for which
                    your data is&nbsp;collected. We&nbsp;use the following retention periods for the following
                    (categories) of&nbsp;personal data: 3&nbsp;years.
                </p>
                <p><strong>Sharing personal data with third parties</strong><br/>
                    Aixsurge does not sell your data to&nbsp;third parties and only provides it&nbsp;if&nbsp;this
                    is&nbsp;necessary
                    for the execution of&nbsp;our agreement with you or&nbsp;to&nbsp;comply with a&nbsp;legal
                    obligation.
                    We&nbsp;enter into a&nbsp;processor agreement with companies that process your data on&nbsp;our
                    behalf to&nbsp;ensure the same level
                    of&nbsp;security and confidentiality of&nbsp;your data. Aixsurge remains responsible for these
                    processes.</p>

                <p><strong>Cookies, or&nbsp;similar techniques, that we&nbsp;use</strong><br/>
                    Aixsurge uses functional, analytical and tracking cookies. A&nbsp;cookie is&nbsp;a&nbsp;small
                    text file that is&nbsp;stored in&nbsp;the
                    browser of&nbsp;your computer, tablet or smartphone when you first visit this website. Aixsurge
                    uses cookies with a&nbsp;purely technical functionality. This ensure that the website works properly
                    and that, for example, your preferred settings are remembered. These cookies are also used to
                    to&nbsp;make the website work properly and to&nbsp;optimize&nbsp;it. We&nbsp;also place cookies
                    that track your surfing behavior so&nbsp;that we&nbsp;can offer customized content and
                    advertisements. On&nbsp;your first visit to&nbsp;our website, we&nbsp;already informed you about
                    these cookies
                    and asked permission to&nbsp;place them. You can unsubscribe from cookies by&nbsp;setting your
                    internet browser so&nbsp;that it&nbsp;no&nbsp;longer stores cookies. In&nbsp;addition, you can also
                    delete all information previously stored via the settings of&nbsp;your browser.
                </p>

                <p>Cookies are also placed on&nbsp;this website by&nbsp;third parties. These are, for example,
                    advertisers and/or social media companies. Below is&nbsp;an&nbsp;overview:</p>

                <p>Cookie: Google Analytics. Name: _utma. Function: Analytical cookie that measures website visits
                    Retention period: 2&nbsp;years</p>

                <p><strong>View, modify or&nbsp;delete data</strong><br/>
                    You have the right to&nbsp;view, correct or&nbsp;delete your
                    personal data. In&nbsp;addition, you have the right to&nbsp;withdraw your consent to&nbsp;data
                    processing or&nbsp;to&nbsp;object to&nbsp;the processing of&nbsp;your personal data
                    by&nbsp;Aixsurge
                    and you have the right to&nbsp;data portability. This means that you can submit a&nbsp;request
                    to&nbsp;us to&nbsp;send the personal data we&nbsp;have about you in&nbsp;a&nbsp;computer file
                    to&nbsp;you or&nbsp;another organization mentioned by&nbsp;you. You can send a&nbsp;request for
                    access, correction, deletion, data transfer of&nbsp;your personal data or&nbsp;request for
                    withdrawal of&nbsp;your consent or&nbsp;objection to&nbsp;the processing of&nbsp;your personal
                    data
                    to&nbsp;help@aixsurge.com. To ensure that the request for access has been made by&nbsp;you,
                    we&nbsp;ask you to&nbsp;enclose a&nbsp;copy of&nbsp;your proof of&nbsp;identity with the
                    request.
                    Make your passport photo, MRZ (machine readable zone, the strip with numbers at&nbsp;the bottom
                    of&nbsp;the passport), passport number and Citizen Service Number (BSN) black in&nbsp;this copy.
                    This is&nbsp;to&nbsp;protect your privacy. We&nbsp;will respond to&nbsp;your request
                    as&nbsp;quickly
                    as&nbsp;possible, but within four weeks. Aixsurge would also like to&nbsp;point out that you
                    have
                    the option to&nbsp;file a&nbsp;complaint with the national supervisory authority, the Dutch Data
                    Protection Authority.</p>

                <p><strong>How we&nbsp;protect personal data</strong><br/>
                    Aixsurge takes the protection of&nbsp;your data seriously and takes
                    appropriate measures to&nbsp;prevent misuse, loss, unauthorized access, unwanted disclosure and
                    unauthorized modification. If&nbsp;you have the impression that your data is&nbsp;not properly
                    secured or&nbsp;there are indications of&nbsp;misuse, please contact our customer service
                    or&nbsp;via help@aixsurge.com. Aixsurge has taken the following measures to&nbsp;protect your
                    personal data:</p>

                <p>Security software, such as&nbsp;a&nbsp;virus scanner and firewall. TLS (formerly SSL)
                    We&nbsp;send
                    your data via a&nbsp;secure internet connection. You can see this in&nbsp;the address
                    bar &lsquo;https&rsquo; and the padlock in&nbsp;the address bar. DKIM, SPF and DMARC are three
                    internet standards that we&nbsp;use to&nbsp;prevent you from receiving emails on&nbsp;our behalf
                    that contain viruses, are spam or&nbsp;are intended to&nbsp;obtain personal (login) data. DNSSEC
                    is&nbsp;an&nbsp;additional security (in&nbsp;addition to&nbsp;DNS) for converting a&nbsp;domain
                    name
                    (aixsurge.com) to&nbsp;the associated&nbsp;IP address (server name); it&nbsp;is&nbsp;provided
                    with
                    a&nbsp;digital signature. You can have that signature checked automatically. This way
                    we&nbsp;prevent you from being redirected to&nbsp;a&nbsp;false&nbsp;IP address.</p>
            </div>
        </div>
    );
}

export default Privacy;
