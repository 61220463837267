import React from "react";
import { Widget } from "@typeform/embed-react"
import cx from "classnames";
import styles from "./Welcome.module.scss";

const Welcome = () => {
    return (
            <Widget id="s2bXO5Bb" className={styles["welcome__layout"]} />
    );
}

export default Welcome;
