import React from "react";
import ReactDOM from "react-dom/client";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home from "./Home";
import Welcome from "./Welcome";
import Privacy from "./Privacy";
import "./index.css";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/welcome",
        element: <Welcome/>,
    },
    {
        path: "/wiki/privacy",
        element: <Privacy/>,
    },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
